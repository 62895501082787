import { Box, Grid, List, ListItem, Typography, styled } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const ListContainer = styled(List)`
    & li {
      padding: 0;
      justify-content: space-between;
    }
  `;

  const ContactText = styled(Typography)`
    padding-bottom: 16px;
    font-family: var(--font-secondary);
    & a {
      color: black;
      text-decoration: none;
      font-weight: bold;
    }
  `;

  const TimeTable = styled(Typography)`
    font-family: var(--font-secondary);
    min-width: 95px;
  `;
  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Contact us</title>
      </Helmet>

      <Box
        sx={{
          padding: { xs: "25px", md: "50px" },
        }}
      >
        <Grid
          container
          sx={{
            width: { xs: "100%", md: "75%" },
            margin: "0 auto",
            gap: { xs: "30px", md: "0px" },
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              sx={{ paddingBottom: "8px", fontFamily: "var(--font-primary)" }}
            >
              Salon Location
            </Typography>
            <Typography sx={{ paddingBottom: "16px" }}>
              Walnut way, Ruislip, HA4 6TD
            </Typography>
            <Typography
              variant="h5"
              sx={{ paddingBottom: "16px", fontFamily: "var(--font-primary)" }}
            >
              Contact Details
            </Typography>
            <ContactText>
              Phone:
              <a
                href="tel:07552833606"
                aria-label="Luminous beauty contact details"
              >
                07552 833606
              </a>
            </ContactText>
            <ContactText>
              Email:
              <a href="mailto:luminousbeautybyneha@gmail.com">
                luminousbeautybyneha@gmail.com
              </a>
            </ContactText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "var(--font-primary)",
              }}
            >
              Opening Hours
            </Typography>
            <ListContainer sx={{ width: { xs: "100%", sm: "75%" } }}>
              <ListItem>
                <TimeTable>Monday</TimeTable>
                <TimeTable>10am – 7pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable>Tuesday</TimeTable>
                <TimeTable>10am – 7pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable>Wednesday</TimeTable>
                <TimeTable>10am – 7pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable>Thursday</TimeTable>
                <TimeTable>10am – 7pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable>Friday</TimeTable>
                <TimeTable>10am – 7pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable>Saturday</TimeTable>
                <TimeTable>10am – 5pm</TimeTable>
              </ListItem>
              <ListItem>
                <TimeTable sx={{ fontWeight: "bold" }}>Sunday</TimeTable>
                <TimeTable sx={{ fontWeight: "bold" }}>Closed</TimeTable>
              </ListItem>
            </ListContainer>
          </Grid>
        </Grid>
        <Box
          sx={{
            // width: { xs: "100%", md: "75%" },
            margin: "0 auto",
            gap: { xs: "30px", md: "0px" },
            paddingTop: "50px",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9923.595695612265!2d-0.3986645!3d51.5517524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766dcc37725ba3%3A0xc82e9464e322ece7!2sLuminous%20Beauty!5e0!3m2!1sen!2sin!4v1696087650512!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
          />
        </Box>
      </Box>
    </>
  );
};

export default Contact;
