import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import ManicurePedicureDetails from "../../assets/images/manicure-pedicure-treatment-details-HD.webp";
import SendIcon from "@mui/icons-material/Send";
import { manicurePedicuresData } from "../../utils/price";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const ManicurePedicure = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  const ManicurePedicureContainer = styled(Box)`
    & img {
      background: #ffffff;
      object-fit: contain;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Manicure and pedicure</title>
      </Helmet>

      <ManicurePedicureContainer>
        <Banner
          bannerImage={ManicurePedicureDetails}
          bannerTitle="Manicure and pedicure"
          bannerDescription="Relax and unwind by letting your hands and feet get a pampering with our manicures and pedicures."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              These treatments are for fingernails and toenails, hands and feet
              involve the following steps.
            </Typography>
            <List>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Soaking fingers/feet
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Cutting (if need), filing, and shaping the nails
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Pushing and clipping the dead cuticles
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Scrubbing hands and feet with exfoliator
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Moisturise the hands and feet.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Application of nail colour (Normal or Gel)
              </ListItem>
            </List>
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              <strong>Gel </strong>is a very long-lasting polish. It gives your
              nails a added protection which keeps your nails healthy and
              strong! Gel is cured under a LED/UV lamp which means it is dry
              immediately so no more waiting for your nails to dry naturally. It
              gives your nails the look and feel of natural nails!
            </Typography>
            <br />
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              Regular Manicure and Pedicure will improve the nail health,
              hydrate hands and feet, promote faster nail growth, moisturise,
              and maintain cuticles and enhance the appearance of hands and
              feet.
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {manicurePedicuresData.map((row, index) => (
                    <TableRow key={index}>
                      <TableData>{row.service}</TableData>
                      <TableData>{row.price}</TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </ManicurePedicureContainer>
    </>
  );
};

export default ManicurePedicure;
