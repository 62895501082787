import React from "react";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import EyebrowEyelash from "../../assets/images/eyebrow-carousel-transformed-HD.webp";
import { eyebrowEyelashData } from "../../utils/price";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const EyebrowsEyelash = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | EyEyebrows and eyelashe</title>
      </Helmet>
      <Box>
        <Banner
          bannerImage={EyebrowEyelash}
          bannerTitle="Eyebrows and eyelash"
          bannerDescription="Eyebrows and eyelash tinting
          involves dying the lashes with safe, natural semi-permanent tints,
          to give them a natural and darker appearance."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              <strong>
                PATCH TEST IS REQUIRED 24 – 48 HOURS BEFORE THE TREATMENT.
              </strong>
              <br />
              <br />
              Many different eyelash and eyebrow tint colours are available like
              blues, browns, blacks and greys that can go with your skin and
              colour your hair perfectly. Tinting gives your lashes the look as
              if you’re wearing mascara without wearing any makeup. <br />
              <br /> <strong>Lash lift </strong> is a cosmetic procedure that’s
              meant to curl your existing eyelashes, giving the appearance of
              longer, fuller and longer looking lashes. To enhance the look it
              can be paired with eyelash tint.
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {eyebrowEyelashData.map((row, index) => (
                    <TableRow key={index}>
                      <TableData>{row.service}</TableData>
                      <TableData>{row.price}</TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EyebrowsEyelash;
