/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import Logo from "../../assets/images/luminousbeaty-logo.png";

interface MetaTag {
  itemprop?: string;
  name?: string;
  property?: string;
  content: string;
}

interface CommonMetaTag {
  name?: string;
  "http-eqipt"?: string;
  content: string;
}

const Seo = () => {
  const description =
    "Discover the ultimate beauty experience at Luminous Beauty. We offer a range of luxurious treatments including threading, facials, manicures, pedicures, and more. Our skilled team of professionals is dedicated to enhancing your natural beauty and leaving you feeling refreshed and rejuvenated. Book your appointment today for a personalized pampering session.";
  let sitewideMetaTags: (CommonMetaTag | MetaTag)[] = [
    {
      name: `googlebot`,
      content: "googlebot",
    },
    {
      name: `msnbot`,
      content: "msnbot",
    },
    {
      name: `author`,
      content: "Luminous Beauty",
    },
    {
      name: `apple-mobile-web-app-title`,
      content: "Luminous Beauty",
    },
    {
      name: `application-name`,
      content: "Luminous Beauty",
    },
    {
      name: `msapplication-TileColor`,
      content: "msapplication-TileColor",
    },
    {
      name: `theme-color`,
      content: "#ffffff",
    },
    {
      name: `twitter:creator`,
      content: "@luminousbeauty",
    },
    {
      name: `twitter:site`,
      content: "@luminousbeauty",
    },
    // {
    //   name: `facebook-domain-verification`,
    //   content: siteWideMeta?.facebookDomainVerification,
    // },
    {
      property: `og:locale`,
      content: "en_GB",
    },
    {
      property: `og:site_name`,
      content: "Luminous Beauty",
    },
    {
      property: `og:url`,
      content: typeof window !== "undefined" ? window.location.href : "",
    },
    // {
    //   name: `fb:app_id`,
    //   content: siteWideMeta?.facebookAppId,
    // },
  ];

  let pageSpecificMetaTags: MetaTag[] = [
    {
      itemprop: "description",
      content: description,
    },
    {
      itemprop: "image",
      content: Logo,
    },
    {
      name: "description",
      content: description,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:title",
      content: "Luminous Beauty",
    },
    {
      name: "twitter:image",
      content: Logo,
    },
    {
      name: "twitter:image:secure_url",
      content: Logo,
    },
    {
      name: "twitter:image:alt",
      content: "Luminous Beauty",
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:image",
      content: Logo,
    },
    {
      property: "og:image:secure_url",
      content: Logo,
    },
    {
      property: "og:image:alt",
      content: "Luminous Beauty",
    },
    {
      property: "og:title",
      content: "Luminous Beauty",
    },
  ];

  sitewideMetaTags = sitewideMetaTags?.filter((metaTag) => metaTag.content);

  pageSpecificMetaTags = pageSpecificMetaTags?.filter(
    (metaTag: MetaTag | undefined) => metaTag !== undefined && metaTag.content
  );

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  //   / Structured Data Configuration /
  const sdSchemaOrg = {
    "@type": "Organization",
    name: "Luminous Beauty",
  };

  const commonMetaTags: CommonMetaTag[] = [
    {
      name: "googlebot",
      content: "notranslate",
    },
    {
      name: "rating",
      content: "General",
    },
    {
      "http-eqipt": "Content-Type",
      content: "text/html; charset=UTF-8",
    },
  ];

  const sdSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: typeof window !== "undefined" ? window.location.href : "",
    headline: "Luminous Beauty",
    inLanguage: "en",
    mainEntityOfPage: typeof window !== "undefined" ? window.location.href : "",
    description: description,
    name: "Luminous Beauty",
    author: "Luminous Beauty",
    copyrightHolder: sdSchemaOrg,
    copyrightYear: currentYear,
    creator: sdSchemaOrg,
    publisher: sdSchemaOrg,
    image: {
      "@type": "ImageObject",
      url: Logo,
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      meta={sitewideMetaTags
        .concat(pageSpecificMetaTags)
        .concat(commonMetaTags)}
    >
      <script type="application/ld+json" defer>
        {JSON.stringify(sdSchema)}
      </script>
    </Helmet>
  );
};

export default Seo;
