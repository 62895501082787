import React from "react";
import HomeBannerImage from "../../assets/images/home-banner-HD.webp";
import FacialImage from "../../assets/images/facial-carousel.webp";
import FacialThreadingImage from "../../assets/images/threading-carousel.webp";
import EyebrowsEyelashCarousel from "../../assets/images/eyebrows-eyelash-carosuel.webp";
import WaxingCarousel from "../../assets/images/waxing-carousel.webp";
import ManicurePadicureCarousel from "../../assets/images/manicure-pedicure-carousel.webp";
import MassagesCarousel from "../../assets/images/massages-carousel.webp";
import Carousel from "react-multi-carousel";
import { Box, Grid, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const BeautyTextContainer = styled(Box)`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fdf9f7;
`;

const BeautyTextTitle = styled(Typography)`
  font-size: 35px;
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 1;
  padding-bottom: 15px;
`;

const BannerTitle = styled(Typography)`
  line-height: 1;
  padding-bottom: 15px;
  font-family: var(--font-primary);
`;

const DesktopBackgroundImage = styled(Box)`
  & img {
    height: calc(100vh - 107px);
    width: 100%;
  }
`;

const MobileBackgroundImage = styled(Box)`
  & img {
    width: 100%;
    height: 280px;
  }
`;

const BannerContainer = styled(Box)`
  position: relative;
`;

const BannerContent = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const CarouselItem = styled(Box)`
  margin: 0px 20px;
  border-radius: 10px;
  position: relative;

  & img {
    border-radius: 7px;
    width: 100%;
    height: 400px;
  }
`;

const CarouselContent = styled(Box)`
  color: #000000;
  position: absolute;
  bottom: 0px;
  padding: 15px;
`;

const CarouselTitle = styled(Typography)`
  font-size: 25px;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 110%;
  padding-bottom: 10px;
`;

const CarouselDescription = styled(Typography)`
  font-size: 14px;
  font-family: var(--font-secondary);
  font-weight: 300;
  line-height: 24px;
`;

const HomeTestinomialTitle = styled(Typography)`
  font-size: 28px;
  font-weight: 400;
  line-height: 110%;
  padding-bottom: 5px;
  text-align: center;
  display: flex;
  font-family: var(--font-primary);
  justify-content: center;
`;

const TreatmentBannerButton = styled("button")`
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-primary);
  font-size: 18px;
  min-height: 3.275rem;
  min-width: 10.325rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 10px;
  font-weight: 700;
`;

const Home = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Helmet>
        <title>Luminous Beauty | Home</title>
      </Helmet>

      <section>
        <BannerContainer
          sx={{ height: { xs: "280px", md: "calc(100vh - 107px)" } }}
        >
          <BannerContent
            container
            sx={{
              padding: { xs: "0px 25px", md: "0px 50px" },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ textAlign: { xs: "center", sm: "left" }, color: "#FFFFFF" }}
            >
              <BannerTitle sx={{ fontSize: { xs: "42px", md: "64px" } }}>
                <strong>Elevate</strong>Your <strong>Beauty</strong>
                <br /> <strong>Elevate</strong>Your <strong>Spirit</strong>
              </BannerTitle>
              <TreatmentBannerButton
                onClick={() =>
                  (window.location.href =
                    "https://www.fresha.com/a/luminous-beauty-ruislip-uk-28-walnut-way-pgzlqpfe/booking?menu=true&pId=1046835")
                }
              >
                Book Appointment
              </TreatmentBannerButton>
            </Grid>
          </BannerContent>
          <DesktopBackgroundImage sx={{ display: { xs: "none", md: "block" } }}>
            <LazyLoadImage
              alt="banner-image"
              src={HomeBannerImage}
              height="calc(100vh - 107px)"
              width="100%"
              effect="blur"
            />
          </DesktopBackgroundImage>
          <MobileBackgroundImage sx={{ display: { xs: "flex", md: "none" } }}>
            <LazyLoadImage
              alt="banner-image"
              src={HomeBannerImage}
              height="auto"
              width="100%"
              effect="blur"
            />
          </MobileBackgroundImage>
        </BannerContainer>
      </section>

      <BeautyTextContainer>
        <Grid
          container
          sx={{
            padding: { xs: "0px 25px", md: "0px 50px" },
            margin: "0 auto",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} md={9} sx={{ textAlign: "center" }}>
            <BeautyTextTitle>A Beauty Bliss: Luminous beauty</BeautyTextTitle>
            <Typography
              sx={{ fontSize: "14px", fontFamily: "var(--font-secondary)" }}
            >
              Experience the brilliance of beauty at Luminous Beauty with our
              exclusive curated services, meticulously designed to enhance your
              radiance. Embrace a transformative journey where each treatment
              brings out the luminous charm that sets you apart. Discover the
              art of luminosity, and let Luminous Beauty be your guiding light
              to timeless allure.
            </Typography>
          </Grid>
        </Grid>
      </BeautyTextContainer>

      <section className="home-carousel">
        <Box sx={{ padding: { xs: "0px 10px", md: "0px 30px" } }}>
          <Carousel
            responsive={responsive}
            draggable={false}
            autoPlay={true}
            autoPlaySpeed={4000}
            arrows={false}
            infinite
            showDots={true}
          >
            <CarouselItem>
              <Link to="/treatments/threading">
                <LazyLoadImage
                  alt="threading-carousel-image"
                  src={FacialThreadingImage}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Threading</CarouselTitle>
                  <CarouselDescription>
                    Effortlessly achieve a flawless, hair-free look with our
                    Facial Hair Removal treatment - Embrace smooth, confident
                    beauty at its finest!
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
            <CarouselItem>
              <Link to="/treatments/eyebrows-eyelash">
                <LazyLoadImage
                  alt="eyebrow-eyelash-carousel-image"
                  src={EyebrowsEyelashCarousel}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Eyebrows and eyelash treatments</CarouselTitle>
                  <CarouselDescription>
                    Transform your gaze with our exquisite Eyebrows and Eyelash
                    treatment - Unleash the allure of your eyes, one stroke at a
                    time!
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
            <CarouselItem>
              <Link to="/treatments/waxing">
                <LazyLoadImage
                  alt="waxing-carousel-image"
                  src={WaxingCarousel}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Waxing</CarouselTitle>
                  <CarouselDescription>
                    Experience the ultimate silky-smooth skin with our Waxing
                    treatment - Embrace confidence in every step!
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
            <CarouselItem>
              <Link to="/treatments/facial">
                <LazyLoadImage
                  alt="facial-carousel-image"
                  src={FacialImage}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Facial</CarouselTitle>
                  <CarouselDescription>
                    Indulge in a rejuvenating journey with our Facial treatment
                    - Unveil radiant, glowing skin that captivates hearts.
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
            <CarouselItem>
              <Link to="/treatments/manicure-pedicure">
                <LazyLoadImage
                  alt="manicure-pedicure-carousel-image"
                  src={ManicurePadicureCarousel}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Manicure and pedicure</CarouselTitle>
                  <CarouselDescription>
                    Step into pure bliss with our Manicure and Pedicure
                    treatment - Pamper your hands and feet to perfection,
                    elevating your elegance.
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
            <CarouselItem>
              <Link to="/treatments/massages">
                <LazyLoadImage
                  alt="massages-carousel-image"
                  src={MassagesCarousel}
                  width="100%"
                  height={400}
                  effect="blur"
                />
                <CarouselContent>
                  <CarouselTitle>Massages</CarouselTitle>
                  <CarouselDescription>
                    Escape to a realm of relaxation with our Massages - A
                    blissful sanctuary to revitalize mind, body, and soul.
                  </CarouselDescription>
                </CarouselContent>
              </Link>
            </CarouselItem>
          </Carousel>
        </Box>
      </section>

      <section>
        <Box sx={{ padding: "50px" }}>
          <Box sx={{ padding: "20px 0px" }}>
            <HomeTestinomialTitle>
              Indulge in the brilliance of beauty with Luminous Beauty's curated
              services, tailored to enhance your glow
            </HomeTestinomialTitle>
            <Typography sx={{ textAlign: "center" }}>
              - Luminous beauty
            </Typography>
          </Box>
        </Box>
      </section>
    </div>
  );
};

export default Home;
