import React, { KeyboardEvent, useState } from "react";
import LuminousBeautyLogo from "../../assets/images/luminousbeaty-logo.png";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Instagram from "../../assets/images/Instagram";
import Facebook from "../../assets/images/Facebook";
import PhoneCall from "../../assets/images/PhoneCall";

const navLinks = [
  { title: "HOME", path: "/" },
  { title: "ABOUT", path: "/about" },
  { title: "TREATMENTS", path: "/treatments" },
  { title: "PRICE LIST", path: "/price-list" },
  { title: "CONTACT", path: "/contact" },
];

const ListItems = styled(ListItem)`
  & a {
    text-decoration: none;
    color: #212121;
    font-size: 18px;
    font-family: var(--font-primary);
  }
`;

const PhoneContainer = styled(Box)`
  display: flex;
  & a {
    text-decoration: none;
    color: #212121;
    font-size: 18px;
    font-family: var(--font-primary);
    font-family: moris;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
`;

const ListContainer = styled(List)`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavLinkContainer = styled(Toolbar)`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flexgrow: 1;
  padding: 0px !important;
`;

const SideBarLogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const NavlinkSocialMediaLink = styled("a")`
  display: flex;
`;

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();
  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent): void => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  return (
    <header>
      <AppBar
        position="static"
        sx={{
          background: "#f9f6f1",
          padding: 0,
          boxShadow: "none",
          position: { xs: "fixed", md: "relative" },
          zIndex: "99",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "0px 25px", md: "0px 50px" } }}
        >
          <Toolbar
            disableGutters
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box>
                <Link to="/">
                  <img
                    src={LuminousBeautyLogo}
                    width={100}
                    height={100}
                    alt="luminous-beauty-salon"
                  />
                </Link>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "row-reverse",
                  sm: "row-reverse",
                  md: "row",
                },
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "end",
                }}
              >
                <IconButton
                  className={"classes.menuButton"}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon
                    style={{ width: "40px", height: "40px", color: "#000000" }}
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <NavlinkSocialMediaLink
                    href="https://www.instagram.com/luminousbeauty_by_neha/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                    target="_blank"
                    aria-label="Luminous beauty instagram page"
                  >
                    <Instagram color="#212121" width="33px" height="33px" />
                  </NavlinkSocialMediaLink>
                </Box>
                <Box>
                  <NavlinkSocialMediaLink
                    href="https://www.facebook.com/people/Luminous-Beauty-By-Neha/100094517345174/"
                    target="_blank"
                    aria-label="Luminous beauty facebook page"
                  >
                    <Facebook color="#212121" width="30px" height="30px" />
                  </NavlinkSocialMediaLink>
                </Box>
              </Box>
              <PhoneContainer sx={{ display: { xs: "flex", md: "none" } }}>
                <a
                  href="tel:07552833606"
                  aria-label="Luminous beauty contact details"
                >
                  <PhoneCall color="#212121" width="26px" height="26px" />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    07552 833606
                  </Typography>
                </a>
              </PhoneContainer>
              <NavLinkContainer
                sx={{
                  display: { xs: "none", md: "flex", justifyContent: "end" },
                }}
              >
                <ListContainer>
                  {navLinks.map(
                    (link: { title: string; path: string }, index) => (
                      <ListItems
                        key={index}
                        sx={{
                          width: "max-content",
                          padding: { md: "8px", lg: "8px 16px" },
                        }}
                      >
                        <Link
                          to={`${link?.path}`}
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                          style={{
                            color:
                              link?.path === location?.pathname ||
                              location?.pathname.includes(
                                link?.title?.toLowerCase()
                              )
                                ? "#ff948e"
                                : "#212121",
                          }}
                        >
                          {link.title}
                        </Link>
                      </ListItems>
                    )
                  )}
                </ListContainer>
              </NavLinkContainer>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <PhoneContainer>
                <a
                  href="tel:07552833606"
                  aria-label="Luminous beauty contact details"
                >
                  <PhoneCall color="#212121" width="26px" height="26px" />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    07552 833606
                  </Typography>
                </a>
              </PhoneContainer>
              <Box>
                <NavlinkSocialMediaLink
                  href="https://www.instagram.com/luminousbeauty_by_neha/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                  target="_blank"
                  aria-label="Luminous beauty instagram page"
                >
                  <Instagram color="#212121" width="33px" height="33px" />
                </NavlinkSocialMediaLink>
              </Box>
              <Box>
                <NavlinkSocialMediaLink
                  href="https://www.facebook.com/people/Luminous-Beauty-By-Neha/100094517345174/"
                  target="_blank"
                  aria-label="Luminous beauty facebook page"
                >
                  <Facebook color="#212121" width="30px" height="30px" />
                </NavlinkSocialMediaLink>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{ sx: { width: "75%", background: "#f9f6f1" } }}
        >
          <SideBarLogoContainer>
            <Link to="/">
              <img
                src={LuminousBeautyLogo}
                width={150}
                height={150}
                alt="luminous-beauty-salon"
              />
            </Link>
          </SideBarLogoContainer>
          <Divider />
          <Box
            className={"classes.list"}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {navLinks.map((link: { title: string; path: string }, index) => {
                return (
                  <ListItems key={index}>
                    <Link
                      to={`${link?.path}`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      style={{
                        color:
                          link?.path === location?.pathname ||
                          location?.pathname.includes(
                            link?.title?.toLowerCase()
                          )
                            ? "#ff948e"
                            : "#212121",
                      }}
                    >
                      {link.title}
                    </Link>
                  </ListItems>
                );
              })}
            </List>
          </Box>
          {/* <Divider /> */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "8px 16px",
            }}
          >
            <Box>
              <NavlinkSocialMediaLink
                href="https://www.instagram.com/luminousbeauty_by_neha/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
                aria-label="Luminous beauty instagram page"
              >
                <Instagram color="#212121" width="33px" height="33px" />
              </NavlinkSocialMediaLink>
            </Box>
            <Box>
              <NavlinkSocialMediaLink
                href="https://www.facebook.com/people/Luminous-Beauty-By-Neha/100094517345174/"
                target="_blank"
                aria-label="Luminous beauty facebook page"
              >
                <Facebook color="#212121" width="30px" height="30px" />
              </NavlinkSocialMediaLink>
            </Box>
          </Box> */}
        </Drawer>
      </Box>
    </header>
  );
};

export default Header;
