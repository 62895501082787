export const eyebrowEyelashData = [
  { service: "Eyebrows Tint", price: "£10" },
  { service: "Eyelashes Tint", price: "£10" },
  { service: "Eyebrows and eyelash tint", price: "£17" },
  { service: "Eyelash Lift", price: "£40" },
];

export const manicurePedicuresData = [
  { service: "Manicure", price: "£18" },
  { service: "Manicure with Gel", price: "£30" },
  { service: "Manicure with Gel on & off", price: "£35" },
  { service: "Pedicure", price: "£25" },
  { service: "Pedicure with Gel", price: "£30" },
  { service: "Pedicure with Gel on & off", price: "£35" },
  { service: "File and Polish", price: "£10" },
  { service: "Gel on (fingers or toes)", price: "£20" },
  { service: "Gel off (fingers or toes)", price: "£20" },
  { service: "Gel on & off (fingers or toes)", price: "£25" },
];

export const threadingData = [
  { service: "Full Face (Excluding Eyebrow)", price: "£15" },
  { service: "Full Face (Including Eyebrow)", price: "£17" },
  { service: "Eyebrows", price: "£5" },
  { service: "Upper lip", price: "£3" },
  { service: "Side of the Face", price: "£7" },
  { service: "Forehead", price: "£4" },
  { service: "Chin", price: "£4" },
  { service: "Neck", price: "£5" },
];

export const waxingData = [
  { service: "Full Face (excluding Eyebrow)", price: "£17" },
  { service: "Full Face (including Eyebrow)", price: "£21" },
  { service: "Eyebrow Tidy-up", price: "£6" },
  { service: "Upper Lip", price: "£4" },
  { service: "Sides of the face", price: "£8" },
  { service: "Forehead", price: "£5" },
  { service: "Chin", price: "£5" },
  { service: "Neck", price: "£5" },
  { service: "Full Arms", price: "£15" },
  { service: "Underarms", price: "£8" },
  { service: "Half Legs", price: "£13" },
  { service: "Full Legs", price: "£20" },
  { service: "Bikini Line", price: "£10" },
  { service: "Brazilian", price: "£25" },
  { service: "Hollywood", price: "£30" },
  { service: "Buttocks", price: "£10" },
  { service: "Stomach", price: "£15" },
  { service: "Back", price: "£15" },
  { service: "Chest", price: "£8" },
];

export const waxingPackagesData = [
  { service: "Full Legs, Full Arms, Under Arms & Bikini Line", price: "£50" },
  {
    service:
      "Full Legs, Full Arms, Under Arms, Bikini Line, Stomach, Chest & Back ",
    price: "£80",
  },
  { service: "Full Legs, Full Arms, Under Arms & Hollywood", price: "£65" },
  {
    service:
      "Full Legs, Full Arms, Under Arms, Hollywood, Stomach, Chest & Back ",
    price: "£100",
  },
];

export const facialData = [
  { service: "Express Facial (30 Minutes)", price: "£30" },
  { service: "Deep cleansing facial (1 hr)", price: "£45" },
];

export const massagesData = [
  { service: "Indian head massage (20 minutes)", price: "£25" },
  { service: "Back and shoulder (30 minutes)", price: "£30" },
  { service: "Full body Swedish massage (1 hr)", price: "£50" },
];
