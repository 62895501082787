import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import {
  eyebrowEyelashData,
  facialData,
  manicurePedicuresData,
  massagesData,
  threadingData,
  waxingData,
  waxingPackagesData,
} from "../../utils/price";
import { Helmet } from "react-helmet";

const PriceList = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    padding: 6px;
    &:first-child {
      width: 85%;
      padding: 0px 20px;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  const PriceListContiner = styled(Grid)`
    display: flex;
    flex-direction: column;
    gap: 25px;
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Price List</title>
      </Helmet>

      <Box>
        <Box>
          <Typography
            variant="h4"
            sx={{
              padding: { xs: "25px", md: "25px 50px" },
              textAlign: "center",
              fontFamily: "var(--font-primary)",
            }}
          >
            Price List
          </Typography>
        </Box>
        <Box>
          {/*-------------------- Price List design for Desktop and tablet view only -----------------*/}

          <Grid
            container
            sx={{
              padding: { xs: "20px 25px", md: "20px 50px" },
              width: { xs: "100%", md: "85%" },
              margin: "0 auto",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <PriceListContiner
              sm={6}
              sx={{
                paddingLeft: "20px",
              }}
            >
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Threading
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {threadingData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Eyebrows and eyelash
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eyebrowEyelashData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={2}>
                              <TableData sx={{ border: "none" }}>
                                (PATCH TEST IS REQUIRED 24 – 48 HOURS BEFORE THE
                                TREATMENT.)
                              </TableData>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Facial
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {facialData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Manicure and pedicure
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {manicurePedicuresData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </PriceListContiner>
            <PriceListContiner
              sm={6}
              sx={{
                paddingLeft: "20px",
              }}
            >
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Waxing
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {waxingData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableData colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Packages
                              </Typography>
                            </TableData>
                          </TableRow>
                          {waxingPackagesData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Massages
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {massagesData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </PriceListContiner>
          </Grid>

          {/*-------------------- Price List design for mobile view only -----------------*/}

          <Grid
            container
            sx={{
              padding: "20px 25px",
              width: "100%",
              display: { xs: "flex", sm: "none" },
            }}
          >
            <PriceListContiner>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Threading
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {threadingData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Eyebrows and eyelash
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eyebrowEyelashData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={2}>
                              <TableData sx={{ border: "none" }}>
                                (PATCH TEST IS REQUIRED 24 – 48 HOURS BEFORE THE
                                TREATMENT.)
                              </TableData>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Waxing
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {waxingData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableData colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Packages
                              </Typography>
                            </TableData>
                          </TableRow>
                          {waxingPackagesData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Facial
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {facialData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Manicure and pedicure
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {manicurePedicuresData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ background: "#fce7e7" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography
                                variant="h4"
                                sx={{ textAlign: "center" }}
                              >
                                Massages
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {massagesData.map((row, index) => (
                            <TableRow key={index}>
                              <TableData>{row.service}</TableData>
                              <TableData>{row.price}</TableData>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </PriceListContiner>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PriceList;
