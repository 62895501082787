import React from "react";
import "./config.css";
import Home from "./Pages/Home";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PriceList from "./Pages/PriceList";
import { Box } from "@mui/material";
import Treatments from "./Pages/Treatments";
import FacialHairRemoval from "./Pages/TreatmentDetails/Threading";
import Facial from "./Pages/TreatmentDetails/Facial";
import Waxing from "./Pages/TreatmentDetails/Waxing";
import Massages from "./Pages/TreatmentDetails/Massages";
import EyebrowsEyelash from "./Pages/TreatmentDetails/EyebrowsEyelash";
import ManicurePedicure from "./Pages/TreatmentDetails/ManicurePedicure";
import "./assets/css/_global.css";
import Seo from "./components/Seo";
import NotFound from "./Pages/404page";

function App() {
  return (
    <div className="App">
      <Seo />
      <BrowserRouter>
        <Header />
        <Box sx={{ paddingTop: { xs: "108px", md: "0px" } }}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/treatments" element={<Treatments />} />
            <Route
              path="/treatments/threading"
              element={<FacialHairRemoval />}
            />
            <Route path="/treatments/facial" element={<Facial />} />
            <Route path="/treatments/waxing" element={<Waxing />} />
            <Route path="/treatments/massages" element={<Massages />} />
            <Route
              path="/treatments/eyebrows-eyelash"
              element={<EyebrowsEyelash />}
            />
            <Route
              path="/treatments/manicure-pedicure"
              element={<ManicurePedicure />}
            />
            <Route path="/price-list" element={<PriceList />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default App;
