import React from "react";
import Instagram from "../../assets/images/Instagram";
import Facebook from "../../assets/images/Facebook";
import { Box, Grid, Typography, styled } from "@mui/material";

const Footer = () => {
  const FooterAddress = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #212121;
    margin: 0;
    font-weight: 600;
    line-height: 180%;
    letter-spacing: 2.96px;
    text-align: center;
    font-family: var(--font-primary);
  `;

  const FooterAppoinmentTitle = styled(Typography)`
    color: #212121;
    font-family: var(--font-primary);
    letter-spacing: 2.4px;
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 40px 0;
    text-align: center;
  `;
  return (
    <footer>
      <Box
        sx={{
          padding: { xs: "60px 25px", md: "60px 50px" },
          backgroundColor: "#fcf3ee",
        }}
      >
        <Grid item sx={{ alignItems: "center", justifyContent: "center" }}>
          <FooterAppoinmentTitle>Book Appoinment</FooterAppoinmentTitle>
          <Typography
            style={{ textAlign: "center" }}
            sx={{
              fontSize: { xs: "36px", sm: "48px", md: "56px" },
              letterSpacing: { xs: "2px", sm: "6px", md: "8px" },
              fontFamily: "var(--font-primary)",
              color: "#9a7f4f",
            }}
          >
            07552 833606
          </Typography>
          <FooterAddress>Walnut way, Ruislip, HA4 6TD</FooterAddress>
        </Grid>

        <Grid
          container
          sx={{ padding: "50px 0px", gap: { xs: "50px", md: "0px" } }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            © 2023 Luminous Beauty. All Rights Reserved.
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <a
                href="https://www.instagram.com/luminousbeauty_by_neha/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
                aria-label="Luminous beauty instagram page"
              >
                <Instagram color="#9a7f4f" width="45px" height="45px" />
              </a>
            </Box>
            <Box>
              <a
                href="https://www.facebook.com/people/Luminous-Beauty-By-Neha/100094517345174/"
                target="_blank"
                aria-label="Luminous beauty facebook page"
              >
                <Facebook color="#9a7f4f" width="42px" height="42px" />
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            E-Mail:
            <a
              href="mailto:luminousbeautybyneha@gmail.com"
              className="footer-email"
              style={{
                color: "#212121",
                textDecoration: "none",
              }}
            >
              luminousbeautybyneha@gmail.com
            </a>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
};

export default Footer;
