import React from "react";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import FacialDetails from "../../assets/images/facial-treatment-details-HD.webp";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const Facial = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Facial</title>
      </Helmet>

      <Box>
        <Banner
          bannerImage={FacialDetails}
          bannerTitle="Facial"
          bannerDescription="The treatment is carefully curated to address your specific needs, leaving you with radiant and rejuvenated skin that reflects your individual beauty."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              I am excited to offer you a personalized and indulgent experience
              with facial treatments. Each facial is meticulously designed to
              cater to your unique skin type and concerns, ensuring that you
              receive nothing short of the perfect facial tailored just for you.
              Our commitment to customization means that every step of the
              treatment is carefully curated to address your specific needs,
              leaving you with radiant and rejuvenated skin that reflects your
              individual beauty.
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableData>
                      Express Facial (30 minutes) <br />
                      (Double cleanse, facial massage and face mask.)
                    </TableData>
                    <TableData>£30</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      Deep Cleanse Facial (1 hour) <br />
                      (Double cleanse, exfoliate, steam, extraction (only if
                      necessary), facial massage and face mask.  All products
                      selected are suitable for your specific concerns and skin
                      needs.)
                    </TableData>
                    <TableData>£45</TableData>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Facial;
