import React from "react";
import FacialTreatment from "../../assets/images/facial-banner.webp";
import Massages from "../../assets/images/body-massge-banner.webp";
import HandsTreatment from "../../assets/images/hands-feet-treatmenent.webp";
import FacialHairRemoval from "../../assets/images/facial-hair-removal.webp";
import EyebrowsEyelashTreatments from "../../assets/images/eyebrows-eyelash-treatment.webp";
import Waxing from "../../assets/images/waxing-banner.webp";
import { Box, Grid, Typography, styled } from "@mui/material";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Treatments = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const TreatmentContainer = styled(Grid)`
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Treatment = styled(Typography)`
    & a {
      padding: 10px 0px;
      background: #ff687aee;
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: var(--font-primary);
      text-decoration: none;
      font-weight: 600;
      color: white;
    }
  `;

  const TreatmentsList = styled(Grid)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 50px;
    width: 100%;
  `;

  const TreatmentBackgroungimage = styled(LazyLoadImage)`
    width: 100%;
    height: 500px;
    opacity: 0.6;
  `;

  const handleTreatmentClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Treatments</title>
      </Helmet>

      <Box>
        <Box>
          <Box>
            <Typography
              variant="h4"
              sx={{
                padding: { xs: "25px", md: "25px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
            >
              Beauty Treatments
            </Typography>
          </Box>
          <Box sx={{ position: "relative" }}>
            {/*-------------------- Treatments List design for Desktop and tablet view only -----------------*/}

            <TreatmentContainer
              container
              sx={{
                gap: { xs: "20px", sm: "0px" },
                padding: "20px 0px",
                display: { xs: "none", sm: "flex" },
              }}
            >
              <TreatmentsList
                item
                xs={12}
                sm={6}
                sx={{ padding: { xs: "0px 25px", sm: "20px 50px" } }}
              >
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link
                    to="/treatments/threading"
                    onClick={handleTreatmentClick}
                  >
                    Threading
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link to="/treatments/waxing" onClick={handleTreatmentClick}>
                    Waxing
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link
                    to="/treatments/manicure-pedicure"
                    onClick={handleTreatmentClick}
                  >
                    Manicure and pedicure
                  </Link>
                </Treatment>
              </TreatmentsList>
              <TreatmentsList
                item
                xs={12}
                sm={6}
                sx={{ padding: { xs: "0px 25px", sm: "20px 50px" } }}
              >
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link
                    to="/treatments/eyebrows-eyelash"
                    onClick={handleTreatmentClick}
                  >
                    Eyebrows and eyelash
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link to="/treatments/facial" onClick={handleTreatmentClick}>
                    Facial
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: { xs: "20px", md: "30px" } }}>
                  <Link
                    to="/treatments/massages"
                    onClick={handleTreatmentClick}
                  >
                    Massages
                  </Link>
                </Treatment>
              </TreatmentsList>
            </TreatmentContainer>

            {/*-------------------- Treatments List design for Mobile view only -----------------*/}

            <TreatmentContainer
              container
              sx={{
                gap: "20px",
                padding: "20px 0px",
                display: { xs: "flex", sm: "none" },
              }}
            >
              <TreatmentsList item sx={{ padding: "0px 25px" }}>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link
                    to="/treatments/threading"
                    onClick={handleTreatmentClick}
                  >
                    Threading
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link
                    to="/treatments/eyebrows-eyelash"
                    onClick={handleTreatmentClick}
                  >
                    Eyebrows and eyelash
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link to="/treatments/waxing" onClick={handleTreatmentClick}>
                    Waxing
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link to="/treatments/facial" onClick={handleTreatmentClick}>
                    Facial
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link
                    to="/treatments/manicure-pedicure"
                    onClick={handleTreatmentClick}
                  >
                    Manicure and pedicure
                  </Link>
                </Treatment>
                <Treatment sx={{ fontSize: "20px" }}>
                  <Link
                    to="/treatments/massages"
                    onClick={handleTreatmentClick}
                  >
                    Massages
                  </Link>
                </Treatment>
              </TreatmentsList>
            </TreatmentContainer>

            <Carousel
              responsive={responsive}
              draggable={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              infinite
            >
              <Box>
                <TreatmentBackgroungimage
                  src={FacialHairRemoval}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
              <Box>
                <TreatmentBackgroungimage
                  src={EyebrowsEyelashTreatments}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
              <Box>
                <TreatmentBackgroungimage
                  src={Waxing}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
              <Box>
                <TreatmentBackgroungimage
                  src={FacialTreatment}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
              <Box>
                <TreatmentBackgroungimage
                  src={HandsTreatment}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
              <Box>
                <TreatmentBackgroungimage
                  src={Massages}
                  alt="treatment-background-image"
                  effect="blur"
                  width="100%"
                  height={500}
                />
              </Box>
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Treatments;
