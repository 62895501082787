import React from "react";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import MassagesDetails from "../../assets/images/massages-treatment-details-HD.webp";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const Massages = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Massages</title>
      </Helmet>

      <Box>
        <Banner
          bannerImage={MassagesDetails}
          bannerTitle="Massages"
          bannerDescription="A range of different types of massage to suit your needs."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              From light to medium pressure which involves the massage
              techniques like strokes, kneading, ironing, tapping, and cupping
              so that you can relax and unwind completely while you enjoy the
              massage. 
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableData>
                      Full Body Massage (1 hr) <br />
                      <br />
                      <Typography
                        sx={{
                          fontFamily: "var(--font-secondary)",
                          width: "80%",
                        }}
                      >
                        A light to medium pressure full body Swedish massage to
                        help you release tension in the body, relieve pain and
                        relax.
                      </Typography>
                    </TableData>
                    <TableData>£50</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      Back, Neck & Shoulder Massage (25 mins)
                      <br />
                      <br />
                      <Typography
                        sx={{
                          fontFamily: "var(--font-secondary)",
                          width: "80%",
                        }}
                      >
                        Back, neck & shoulder massage using light to medium
                        pressure to help release tension in the
                      </Typography>
                    </TableData>
                    <TableData>£30</TableData>
                  </TableRow>
                  <TableRow>
                    <TableData>
                      Indian Head Massage with Oil (20 mins) <br />
                      <br />
                      <Typography
                        sx={{
                          fontFamily: "var(--font-secondary)",
                          width: "80%",
                        }}
                      >
                        Enjoy a light to medium pressure massage whilst seated,
                        with a hair or massage oil. This massage includes back,
                        neck, shoulder, face & scalp for that full on
                        relaxation.
                      </Typography>
                    </TableData>
                    <TableData>£25</TableData>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Massages;
