import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const About = () => {
  const ContactText = styled(Typography)`
    font-family: var(--font-secondary);
    & a {
      color: #fc002b;
      font-weight: bold;
    }
  `;

  const Description = styled(Typography)`
    font-family: var(--font-secondary);
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | About</title>
      </Helmet>

      <Box
        sx={{
          padding: { xs: "25px", md: "50px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "75%" },
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              padding: { xs: "25px", md: "25px 50px" },
              textAlign: "center",
              fontFamily: "var(--font-primary)",
            }}
          >
            About us
          </Typography>
          <br />
          <Box
            sx={{
              padding: { xs: "25px", md: "50px" },
              background: "white",
            }}
          >
            <Description>
              Welcome to Luminous Beauty, your ultimate sanctuary of self-care
              in South Ruislip. I'm Neha, and I'm passionate about providing you
              with an exceptional beauty experience.
            </Description>{" "}
            <br />
            <Description>
              With my qualifications in Level 2 & 3 Beauty Therapy, along with a
              Level 4 certificate in Laser and IPL treatments and other
              accredited qualifications, you can trust that you're in capable
              hands. Your safety and satisfaction are my top priorities, and I
              take pride in offering a relaxed, friendly, and professional
              environment where you'll feel pampered and cared for.
            </Description>{" "}
            <br />
            <Description>
              As a fully qualified and insured beauty therapist, I continuously
              update my skills to bring you the latest advancements in the
              industry. During your initial consultation, we'll discuss your
              needs and concerns to create a treatment plan. Rest assured, I'll
              monitor and adjust the plan regularly to ensure your continued
              satisfaction.
            </Description>{" "}
            <br />
            <Description>
              At Luminous Beauty, you'll receive expert skincare advice,
              exceptional holistic treatments for your wellbeing, and a level of
              personalized service you truly deserve. I'm here to be your
              trusted specialist as you embark on your journey of self-care.
            </Description>
            <br />
            <Description>
              To experience the ultimate in beauty treatments and relaxation,
              get in touch today. I can't wait to welcome you to Luminous Beauty
              and provide you with the exceptional service you deserve.
            </Description>
            <br />
            <ContactText>
              If you would like to discuss more about the treatments and
              services please <Link to="/contact">Contact</Link> with us.
            </ContactText>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default About;
