import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface IBanner {
  bannerImage: string;
  bannerTitle: string;
  bannerDescription: string;
}

const Banner = ({ bannerImage, bannerTitle, bannerDescription }: IBanner) => {
  const TreatmentBannerImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
  `;

  const TreatmentBannerContaier = styled(Grid)`
    min-height: 75vh;
    height: 100%;
  `;

  const TreatmentBannerContent = styled(Grid)`
    margin: 0 auto;
    display: flex;
    align-items: center;
  `;

  const TreatmentBannerTitle = styled(Typography)`
    // font-size: 55px;
    font-weight: 400;
    // line-height: 60px;
    padding-bottom: 1.25rem;
    font-family: var(--font-primary);
  `;

  const TreatmentBannerDescription = styled(Typography)`
    font-family: var(--font-secondary);
    font-weight: 300;
    line-height: 28px;
    // padding-bottom: 3rem;
  `;

  const TreatmentBannerButton = styled("button")`
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: var(--font-primary);
    font-size: 18px;
    min-height: 3.275rem;
    min-width: 10.325rem;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
  `;
  return (
    <div>
      <TreatmentBannerContaier
        container
        sx={{
          padding: { xs: "0", md: "50px 50px" },
        }}
      >
        <Grid item xs={12} md={6}>
          <TreatmentBannerImage
            sx={{
              objectFit: { xs: "cover", md: "cover" },
              borderRadius: { xs: "0px", md: "8px" },
              height: { xs: "280px", md: "425px" },
            }}
            src={bannerImage}
            alt="treatment-image"
            width="100%"
            effect="blur"
          />
        </Grid>
        <TreatmentBannerContent item xs={12} md={6}>
          <Box
            sx={{
              width: { sx: "100%", md: "80%" },
              margin: "0 auto",
              padding: "25px",
            }}
          >
            <TreatmentBannerTitle
              variant="h1"
              sx={{
                fontSize: { xs: "35px", md: "55px" },
                lineHeight: { xs: "45px", md: "60px" },
              }}
            >
              {bannerTitle}
            </TreatmentBannerTitle>
            <TreatmentBannerDescription
              sx={{ paddingBottom: { xs: "2rem", md: "3rem" } }}
            >
              {bannerDescription}
            </TreatmentBannerDescription>
            <TreatmentBannerButton
              onClick={() =>
                (window.location.href =
                  "https://www.fresha.com/a/luminous-beauty-ruislip-uk-28-walnut-way-pgzlqpfe/booking?menu=true&pId=1046835")
              }
            >
              Book Now
            </TreatmentBannerButton>
          </Box>
        </TreatmentBannerContent>
      </TreatmentBannerContaier>
    </div>
  );
};

export default Banner;
