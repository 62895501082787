import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import TreadingDetails from "../../assets/images/threading-treatment-details-HD.webp";
import SendIcon from "@mui/icons-material/Send";
import { threadingData } from "../../utils/price";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const Threading = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Threading</title>
      </Helmet>

      <Box>
        <Banner
          bannerImage={TreadingDetails}
          bannerTitle="Threading"
          bannerDescription="Achieve precise shaping of eyebrows and remove unnecessary hair from face."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              Threading is a popular method of hair removal that originated in
              ancient India and has been used for centuries to shape and remove
              unwanted hair from various parts of the face, including the
              eyebrows, upper and lower lips, forehead, cheeks, and chin. The
              process involves using a cotton or polyester thread that is
              twisted and rolled over the skin to trap and lift unwanted hairs,
              pulling them out from the follicles.
            </Typography>
            <List>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Threading is better and quicker than tweezing.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                It is much cheaper.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                With threading you can achieve precise shaping of eyebrows.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Longer lasting result.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                No ingrown hairs.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Threading will not irritate the skin like waxing.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {threadingData.map((row, index) => (
                    <TableRow key={index}>
                      <TableData>{row.service}</TableData>
                      <TableData>{row.price}</TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Threading;
