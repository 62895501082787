import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
  TableCell,
} from "@mui/material";
import WaxingDetails from "../../assets/images/waxing-treatment-details-HD.webp";
import SendIcon from "@mui/icons-material/Send";
import { waxingData, waxingPackagesData } from "../../utils/price";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";

const Waxing = () => {
  const TableData = styled(TableCell)`
    fontfamily: var(--font-secondary);
    font-weight: 600;
    &:first-child {
      width: 85%;
    }

    &:nth-child(2) {
      width: 15%;
    }
  `;

  return (
    <>
      <Helmet>
        <title>Luminous Beauty | Waxing</title>
      </Helmet>

      <Box>
        <Banner
          bannerImage={WaxingDetails}
          bannerTitle="Waxing"
          bannerDescription="The process pulls the hair out from the root, resulting in smooth skin that lasts for several weeks before new hair growth appears."
        />
        <Grid
          container
          sx={{
            padding: { xs: "25px", md: "25px 50px" },
            justifyContent: "center",
            fontFamily: "var(--font-secondary)",
          }}
        >
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              Waxing is a semi-permanent hair removal method that involves
              applying strip (warm) wax or hot wax to the treatment area and
              then removing the wax along with the unwanted hairs from the
              follicles.
            </Typography>
            <br />
            <Typography sx={{ fontFamily: "var(--font-secondary)" }}>
              <strong>
                Please follow below guidelines to get better waxing experience.
              </strong>
            </Typography>
            <List>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                Exfoliating the skin a day or two before waxing helps remove
                dead skin cells and allows for better hair removal during the
                waxing process.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                To achieve the best results, it's crucial not to shave or remove
                hair for at least three weeks before waxing. This allows the
                hair to grow to the optimal length for effective waxing.
              </ListItem>
              <ListItem sx={{ gap: "10px", alignItems: "baseline" }}>
                <SendIcon sx={{ fontSize: "15px" }} />
                On the day of your waxing treatment, it's important to avoid
                applying any gels, lotions, or oils to the skin. These products
                can create a barrier and make it difficult for the wax to adhere
                properly to the hair.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Divider />
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Price
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {waxingData.map((row, index) => (
                    <TableRow key={index}>
                      <TableData>{row.service}</TableData>
                      <TableData>{row.price}</TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography
              sx={{
                padding: { xs: "10px", md: "10px 50px" },
                textAlign: "center",
                fontFamily: "var(--font-primary)",
              }}
              variant="h4"
            >
              Packages
            </Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {waxingPackagesData.map((row, index) => (
                    <TableRow key={index}>
                      <TableData
                        sx={{
                          fontFamily: "var(--font-secondary)",
                          fontWeight: 600,
                        }}
                      >
                        {row.service}
                      </TableData>
                      <TableData
                        sx={{
                          fontFamily: "var(--font-secondary)",
                          fontWeight: 600,
                        }}
                      >
                        {row.price}
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Waxing;
